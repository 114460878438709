<template>
  <div>
      <!-- 顶部工具条 -->
      <el-row>
          <el-col>
              <el-form :inline='true'>
                  <el-form-item label='关键字:'>
                      <el-input v-model='filters.name' placeholder='姓名/手机号/身份证号/单位名称/培训机构' clearable></el-input>
                  </el-form-item>
                  <el-form-item label="岗位试卷:">
                        <el-select v-model="filters.station" placeholder="请选择岗位试卷" clearable>
                            <el-option
                                v-for="item in stationArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                            <!-- <el-pagination
                                layout="prev, pager, next"
                                @current-change="stationChange"
                                :total="stationPage"
                            >
                            </el-pagination> -->
                        </el-select>
                    </el-form-item>
                  <el-form-item label="行业试卷:">
                        <el-select v-model="filters.trade" placeholder="请选择行业试卷" clearable>
                            <el-option
                                v-for="item in tradeArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                            <!-- <el-pagination
                                layout="prev, pager, next"
                                @current-change="tradeChange"
                                :total="tradePage"
                            >
                            </el-pagination> -->
                        </el-select>
                    </el-form-item>
                  <el-form-item label="成绩:">
                        <el-select v-model="filters.QualifiedStatus" placeholder="请选择成绩" clearable>
                            <el-option
                                v-for="item in QualifiedStatusArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                  <el-form-item label="免培考试:">
                        <el-select v-model="filters.isExamNoTrain" placeholder="请选择是否免培考试" clearable>
                            <el-option
                                v-for="item in isExamNoTrainArr"
                                :key="item.value"
                                :label="item.Name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                  <el-form-item>
                      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                  </el-form-item>
              </el-form>                           
          </el-col>         
      </el-row>
      <!-- 表格主体 -->
      <el-table
        v-loading='listLoading'
        :data="tableData"
        border
        style="width: 100%"
        highlight-current-row
        @current-change='currentChange'
        :cell-style='rowClass'
        :header-cell-style='headerStyle'
        >   
            <el-table-column type='index' label='序号' width='60'></el-table-column>
            <el-table-column prop="Name" label="姓名" width="90"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" width="130"></el-table-column>
            <el-table-column prop="CardNo" label="身份证号" width="180"></el-table-column>
            <!-- <el-table-column prop="PhoneNumber" label="单位名称"></el-table-column> -->
            <el-table-column prop="AgencyInfoName" label="培训机构" show-overflow-tooltip></el-table-column>
            <el-table-column prop="StationName" label="岗位试卷" show-overflow-tooltip></el-table-column>
            <el-table-column prop="TradeName" label="行业试卷" show-overflow-tooltip></el-table-column>
            <el-table-column prop="TotalScore" label="成绩" width="80"></el-table-column>
            <el-table-column prop="TotalScore" label="状态" width="90">
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='scope.row.Qualified'>及格</el-tag>
                    <el-tag type='danger' v-else>不及格</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='HandTime' label='交卷时间' width=""></el-table-column>
            <el-table-column prop="ExamNoTrain" label="是否免培考试">
                <template slot-scope='scope'>
                    <el-tag type='success' v-if='scope.row.ExamNoTrain == 1'>是</el-tag>
                    <el-tag type='danger' v-else>否</el-tag>
                </template>
            </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pages.PageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pages.PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pages.DataCount"
        class='pageBar'>
      </el-pagination>   
  </div>
</template>

<script>
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import Qs from 'qs';
import util from "../../../util/date";
import { getStudentAchievementListPageNew,getDataDictionaryList } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            buttonList:[],
            filters:{
                name:'',
                station:'',
                trade:'',
                QualifiedStatus:'',
                isExamNoTrain:'',
            },
            stationArr: [],
            stationPageIndex: 1,
            stationPage: 10,
            tradeArr: [],
            tradePageIndex: 1,
            tradePage: 10,
            QualifiedStatusArr: [
                {
                Name: '及格',
                value: 1,
            },{
                Name: '不及格',
                value: 0,
            }
            ],
            isExamNoTrainArr: [{
                Name: '是',
                value: 1,
            },{
                Name: '否',
                value: 0,
            }],
            tableData:[],
            currentRow:{},
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            listLoading:false
        }
    },
    methods:{
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerStyle(){
            return 'text-align:center;'
        },
        // 页面数据
        getData(){
            this.listLoading = true
            var params = {
                name:this.filters.name,
                examNoTrain:this.filters.isExamNoTrain,
                isQualified:this.filters.QualifiedStatus,
                stationID:this.filters.station,
                tradeID:this.filters.trade,
                pageIndex:this.pages.PageIndex,
                pageSize:this.pages.PageSize
            }
            getStudentAchievementListPageNew(params).then(res => {
                this.listLoading = false
                if(res.data.Success){
                    this.tableData = res.data.Response.Data
                    this.pages.DataCount = res.data.Response.DataCount
                    this.currentRow = {}
                }else{
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 获取岗位试卷
        getStationPaper() {
            var params = {
                dataType: 1,
                // pageIndex: this.stationPageIndex,
                // pageSize: 10,
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.stationArr = result.Response
                    // this.stationPage = result.Response.DataCount
                }
            })
        },
        stationChange(val) {
            this.stationPageIndex = val
            this.getStationPaper();
        },
        // 获取行业试卷
        getTradePaper() {
            var params = {
                dataType: 0,
                // pageIndex: this.tradePageIndex,
                // pageSize: 10,
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.tradeArr = result.Response
                    // this.tradePage = result.Response.DataCount
                }
            })
        },
        tradeChange(val) {
            this.tradePageIndex = val
            this.getTradePaper();
        },
        // 获取当前行数据
        currentChange(row){
            if(JSON.stringify(row) == '{}'){
                this.$message.error('请选择一行数据')
            }else{
                this.currentRow = row
            }         
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getData()
        },
        // 查询事件
        getStudentAchievementListPageNew(){
            this.pages.PageIndex = 1
            this.getData()
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        //时间格式化
        formatCreateTime: function(row, column) {
            return !row.HandTime || row.HandTime == ""
            ? ""
            : util.formatDate.format(new Date(row.HandTime), "yyyy-MM-dd");
        },
    },
    created(){
        this.getData();
        this.getStationPaper();
        this.getTradePaper();
    },
    mounted(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
</style>